jQuery(document).ready(function($) {
	$('.clifton-content-slider').each(function(){
		var contentSlider =  $(this).find('#content-slider').flexslider({
			slideshow: false,
			prevText: "",
			nextText: "",
			manualControls: '.clifton-content-slider .custom-slider-paging li',
		});
	});

}); /* end of as page load script */